@import "constants";

#team-link-to {
  position: relative;
  top: 100px;
}

#team {
  margin-top: 285px;
  color: white;
}

#team-title {
  font-family: $header-font;
  font-size: 80px;
  line-height: 100px;
}

#team-desc {
  font-family: $body-font;
  font-size: 24px;
  line-height: 30px;
}

#team-listings {
  position: relative;
  display: flex;
  justify-content: space-around;
  margin-top: 122px;

  #body-line-box-4 {
    height: 348px;
    border-bottom: $body-line-size solid $gold;
    border-left: $body-line-size solid $gold;
    left: $body-line-left;
    top: -95px;
    right: 105px;
  }

  .team-member {
    width: 339px;
    border: 3px solid $gold;
    background: $faded-background;
  }

  .team-below-image {
    padding: 44px 34px;

    .member-role {
      font-family: $body-font;
      font-weight: bold;
      color: $grey;
      font-size: 14px;
      letter-spacing: 0.25em;
    }

    .member-name {
      font-family: $header-font;
      font-size: 24px;
      margin-top: 8px;
    }

    .member-info {
      font-family: $body-font;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      margin-top: 30px;
    }

    .member-links {
      margin-top: 16px;

      a {
        font-family: $body-font;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: white;
        text-decoration: none;
        transition: 0.3s color;

        &:hover {
          color: $gold;
        }
      }
    }
  }
}

@media (max-width: $mobile-screen-width) {
  #team-link-to {
    top: -36px;
  }

  #team {
    margin-top: 125px;
    padding: 0 $mobile-content-horizontal-padding;
  }

  #body-line-box-4 {
    display: none;
  }

  #team-title {
    font-size: 48px;
    line-height: 60px;
  }

  #team-desc {
    font-size: 18px;
    line-height: 22px;
  }

  #team-listings {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 40px;

    .team-member {
      width: 314px;

      picture,
      img {
        width: 308px;
        height: 308px;
      }
    }

    .team-below-image {
      padding: 18px 19px 28px;

      .member-name {
        margin-top: 6px;
      }

      .member-info {
        margin-top: 8px;
      }

      .member-links {
        margin-top: 10px;
      }
    }
  }
}
