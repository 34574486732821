#team-link-to {
  position: relative;
  top: 100px;
}

#team {
  color: #fff;
  margin-top: 285px;
}

#team-title {
  font-family: Righteous, sans-serif;
  font-size: 80px;
  line-height: 100px;
}

#team-desc {
  font-family: Oxygen, sans-serif;
  font-size: 24px;
  line-height: 30px;
}

#team-listings {
  justify-content: space-around;
  margin-top: 122px;
  display: flex;
  position: relative;
}

#team-listings #body-line-box-4 {
  height: 348px;
  border-bottom: 3px solid #9e7509;
  border-left: 3px solid #9e7509;
  top: -95px;
  left: 180px;
  right: 105px;
}

#team-listings .team-member {
  width: 339px;
  background: #0e0e0e80;
  border: 3px solid #9e7509;
}

#team-listings .team-below-image {
  padding: 44px 34px;
}

#team-listings .team-below-image .member-role {
  color: #e0e0e0;
  letter-spacing: .25em;
  font-family: Oxygen, sans-serif;
  font-size: 14px;
  font-weight: bold;
}

#team-listings .team-below-image .member-name {
  margin-top: 8px;
  font-family: Righteous, sans-serif;
  font-size: 24px;
}

#team-listings .team-below-image .member-info {
  margin-top: 30px;
  font-family: Oxygen, sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
}

#team-listings .team-below-image .member-links {
  margin-top: 16px;
}

#team-listings .team-below-image .member-links a {
  color: #fff;
  font-family: Oxygen, sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
  text-decoration: none;
  transition: color .3s;
}

#team-listings .team-below-image .member-links a:hover {
  color: #9e7509;
}

@media (max-width: 1140px) {
  #team-link-to {
    top: -36px;
  }

  #team {
    margin-top: 125px;
    padding: 0 20px;
  }

  #body-line-box-4 {
    display: none;
  }

  #team-title {
    font-size: 48px;
    line-height: 60px;
  }

  #team-desc {
    font-size: 18px;
    line-height: 22px;
  }

  #team-listings {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 40px;
  }

  #team-listings .team-member {
    width: 314px;
  }

  #team-listings .team-member picture, #team-listings .team-member img {
    width: 308px;
    height: 308px;
  }

  #team-listings .team-below-image {
    padding: 18px 19px 28px;
  }

  #team-listings .team-below-image .member-name {
    margin-top: 6px;
  }

  #team-listings .team-below-image .member-info {
    margin-top: 8px;
  }

  #team-listings .team-below-image .member-links {
    margin-top: 10px;
  }
}

/*# sourceMappingURL=index.1347593e.css.map */
